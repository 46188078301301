
<template>
  <div>
    <div class="appExperience" v-if="appType!=3 || !isMobile">
      <div class='app_info'>
        <div class="info_left">
          <img :src="info.pictureUrl" @error="imageLoadError" />
        </div>
        <div class="info_right">
          <div class='info-title'>{{ info.name }}</div>
          <div class='info-companyName'>{{ companyName||'' }}</div>
          <el-tooltip placement="top" effect="light" v-if="info.introduce&&info.introduce.length>201">
            <template slot="content">
              <span class="introduce_tooltip">{{info.introduce}}</span>
            </template>
            <div class="info-intrudce">{{ info.introduce }}</div>
          </el-tooltip>
          <div v-else class="info-intrudce">{{ info.introduce }}</div>
          <div class='info-bottom'>
            <div class='app-tag'>{{ info.appTypeName }}</div>
          </div>
        </div>
        <el-button class="app_detail_btn" @click="goAppDetail(info.id)">应用详情</el-button>
        <div class="category">
          <div class="item b" v-for="item in info.sceneTagNames" :key="item">{{item}}</div>
        </div>
      </div>

      <div class='bottom_wrap' :key="key">
        <TextGenerator :info="info" :id="id" v-if="appType == 3"></TextGenerator>
        <ImageRecognizer :info="info" v-if="appType == 4"></ImageRecognizer>

        <template v-if="appType == 5&&info.sceneTagNames">
          <VoiceToText :info="info" v-if="'语音识别'==info.sceneTagNames[0]"></VoiceToText>
          <VoiceClone :info="info" v-if="'语音克隆'==info.sceneTagNames[0]"></VoiceClone>
          <VoiceSynthesis :info="info" v-if="'语音合成'==info.sceneTagNames[0]"></VoiceSynthesis>
        </template>
        <div class='recommend'>
          <div class='recommend-title'
            :style="{ 'backgroundImage' : `url(${ require('@/assets/images/recommendTitle.png') })`}">相关推荐</div>
          <div class="recommend_list">
            <div class="item" :class="{ active: id === item.id }" @click="selectItem(item)"
              v-for="(item,index) in recommendList" :key="index">
              <div class="i_left">
                <img :src="item.picurl" @error="imageLoadError" alt="">
              </div>
              <div class="i_right">
                <div class="i_r_top">{{item.name}}</div>
                <div class="i_r_bot">{{item.companyName}}</div>
              </div>
              <i v-show="id === item.id" class="el-icon-arrow-right"></i>
            </div>
          </div>
          <div class="viewMore" @click="lookMore">查看更多 >></div>
        </div>

      </div>
      <common-footer v-if="!isMobile"></common-footer>
    </div>
    <div class="modelDialogue" v-if="appType==3&&isMobile">
      <TextGeneratorMobile :info="info" :id="id"></TextGeneratorMobile>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import commonFooter from '@/components/commonFooter'
import api from '@/api/api'
import defaultImage from '@/assets/images/defaultImg.svg';
import TextGenerator from './components/TextGenerator.vue'
import TextGeneratorMobile from './components/TextGenerator_mobile.vue'
import ImageRecognizer from './components/ImageRecognizer.vue'
import VoiceToText from './components/VoiceToText.vue'
import VoiceClone from './components/VoiceClone.vue'
import VoiceSynthesis from './components/VoiceSynthesis.vue'
export default {

  data () {
    return {
      baseUrl: '/oss',
      info: {},
      companyName: '',
      recommendList: [],
      selectedIndex: 0,
      id: '',
      appType: '',
      defaultImage,
      key: 0,
      isMobile: false
    }
  },
  components: {
    commonFooter,
    TextGenerator,
    ImageRecognizer,
    VoiceToText,
    VoiceClone,
    VoiceSynthesis,
    TextGeneratorMobile
  },

  created () {
    const { id, companyName, appType } = this.$route.params;
    this.companyName = companyName;
    this.appType = appType
    this.id = id;
    this.isMobile = /Android|iPhone|iPad|iPod|Mobile/i.test(navigator.userAgent);
    this.queryInfo(id);
    this.getInititalApp()
  },
  methods: {
    imageLoadError (event) {
      event.target.src = this.defaultImage;
    },
    queryInfo (id) {
      api.queryGateWayAppDetail(id).then(res => {
        if (res.code == 200) {
          this.info = res.data;
          // console.log(this.info, "ffffff")
          this.info.pictureUrl = window.location.origin + '/ecol/app/image?path=/' + this.info.picture
        }
      })
    },
    goUse () {
      window.open(this.info.appDetailAddVo.url, '_blank')
    },
    handleClick () {
    },
    selectItem (item) {
      this.key++;
      this.id = item.id;
      this.companyName = item.companyName;
      this.queryInfo(this.id);
    },
    goAppDetail (id) {
      this.$router.push({
        path: '/appDetail',
        query: {
          id,
          companyName: this.companyName
        }
      })
    },
    async getInititalApp () {
      const res = await api.queryInititalApp({
        appType: this.appType,
      });
      if (res.code == 200) {
        this.recommendList = res.data.list.map(item => {
          return {
            ...item,
            picurl: window.location.origin + '/ecol/app/image?path=/' + item.picture
          }
        })
        // console.log(this.recommendList, ' this.publiceList')
      }
    },
    lookMore () {
      this.$router.push("/appMarket")
    },
  },

}
</script>
<style lang="scss" scoped>
.appExperience {
  .app_info {
    position: relative;
    box-sizing: border-box;
    width: 1440px;
    height: 192px;
    margin: 0 auto;
    margin-top: 24px;
    background: #ffffff;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    .app_detail_btn {
      position: absolute;
      right: 16px;
      bottom: 16px;
      width: 105px;
      height: 36px;
      background: #2774f7;
      border-radius: 4px;
      font-size: 14px;
      font-family: Source Han Sans CN, Source Han Sans CN-Regular;
      font-weight: 400;

      color: #ffffff;
    }
    .info_left {
      width: 261px;
      height: 160px;
      margin-right: 24px;
      position: relative;
      overflow: hidden;
      border: 1px solid #ece6e6;
      img {
        max-width: 100%;
        max-height: 100%;
        display: block;
        object-fit: contain;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .info_right {
      padding: 8px 0;
      .info-title {
        font-size: 24px;
        font-family: Source Han Sans CN, Source Han Sans CN-Bold;
        font-weight: 700;
        text-align: left;
        color: #333333;
      }
      .info-companyName {
        font-size: 16px;
        font-family: Source Han Sans CN, Source Han Sans CN-Regular;
        font-weight: 400;
        text-align: left;
        color: #333333;
        margin-top: 10px;
      }
      .info-intrudce {
        margin-top: 10px;
        font-size: 14px;
        font-family: Source Han Sans CN, Source Han Sans CN-Regular;
        font-weight: 400;
        text-align: left;
        color: #a8abb2;
        width: 940px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3; /* 限制在5行 */
        overflow: hidden;
      }
      .info-bottom {
        margin-top: 10px;
        /* bottom: 18px;
        position: absolute; */
        display: flex;
        .app-tag {
          font-size: 16px;
          font-family: Source Han Sans CN, Source Han Sans CN-Medium;
          font-weight: 500;
          text-align: left;
          color: #2774f7;
          margin-right: 20px;
        }
      }
    }
    .category {
      display: flex;
      position: absolute;
      top: 24px;
      right: 0;
      .item {
        background: #fff6f3;
        border: 1px solid #f75327;
        border-radius: 4px;
        font-size: 12px;
        font-family: Source Han Sans CN, Source Han Sans CN-Regular;
        font-weight: 400;
        text-align: center;
        color: #f75327;
        padding: 7px 12px;
        margin-right: 16px;
      }
      .item.b {
        background: #eaf2ff;
        border: 1px solid #2774f7;
        color: #2774f7;
      }
    }
  }

  .bottom_wrap {
    margin: 0 auto;
    margin-bottom: 54px;
    box-sizing: border-box;
    padding: 16px;
    margin-top: 16px;
    width: 1440px;
    height: 660px;
    background: #ffffff;
    overflow: hidden;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;

    ::v-deep.chartWrap {
      width: 1024px;
      height: 628px;
      position: relative;
      background: #f5f5f5;
      border-radius: 4px;
      .multi_modal_img {
        width: 100%;
        height: 200px;
        background: #f5f5f5;
        display: flex;
        align-items: center;
        justify-content: center;
        .temp_img {
          max-width: 250px;
          max-height: 170px;
          margin-bottom: 10px;
        }
        > div {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .line {
        width: 600px;
        border: 1px solid rgb(230, 227, 227);
        margin: 5px auto;
      }
      .chat_content {
        scroll-behavior: smooth;
        height: 520px;
        box-sizing: border-box;
        overflow-y: auto;
        padding: 24px;
        padding-bottom: 80px;
      }
      .chat_content::-webkit-scrollbar {
        width: 8px; /* 滚动条的宽度 */
        background-color: #f9f9f9; /* 滚动条的背景色 */
      }
      .chat_content::-webkit-scrollbar-thumb {
        background-color: #b3b3b3; /* 滚动条滑块的颜色 */
        border-radius: 4px; /* 滚动条滑块的圆角 */
      }
      .chat_content::-webkit-scrollbar-track {
        background-color: #e1e1e1; /* 滚动条滑道的颜色 */
        border-radius: 4px; /* 滚动条滑道的圆角 */
      }
      .chatRecords {
        > div {
          margin: 10px 0px;
        }
        .ai_msg {
          background: #f5f5f5;
          border-radius: 2px;
          .avatar_wrap {
            display: flex;
            align-items: center;
            img {
              width: 32px;
              height: 32px;
            }
            span {
              font-size: 14px;
              font-family: Source Han Sans CN, Source Han Sans CN-Medium;
              font-weight: 500;
              text-align: left;
              color: #333333;
              margin-left: 8px;
            }
          }
          .word {
            display: inline-block;
            padding: 16px;
            background: #fff;
            margin-top: 8px;
            border-radius: 4px;
            font-size: 14px;
            font-family: Source Han Sans CN, Source Han Sans CN-Regular;
            font-weight: 400;
            text-align: left;
            color: #333333;
            margin-left: 40px;
          }
          .el-image {
            width: 80px;

            margin-left: 40px;
          }
        }
        .person_msg {
          display: flex;
          justify-content: flex-end;
          .word {
            text-align: right;
            padding: 9px 16px;
            background: #2774f7;
            border-radius: 4px;
            font-size: 14px;
            font-family: Source Han Sans CN, Source Han Sans CN-Regular;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }
      .chart-input {
        bottom: 16px;
        position: absolute;
        width: 992px;
        height: 52px;
        background: #ffffff;
        border-radius: 4px;
        left: 50%;
        transform: translateX(-50%);

        .el-input__inner {
          width: 992px;
          height: 52px;
          background: #ffffff;
          border-radius: 4px;
          border: none;
          box-sizing: border-box;
          padding-right: 70px;
        }
        .chart-btn {
          width: 48px;
          height: 36px;
          border-radius: 2px;
          background: #2774f7;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          top: 8px;
          right: 4px;
          .el-icon-top {
            font-size: 20px;
            font-weight: bold;
          }
        }
      }
    }
    .recommend {
      width: 368px;

      position: relative;
      .recommend-title {
        box-sizing: border-box;
        padding-left: 16px;
        height: 32px;
        line-height: 33px;
        font-size: 18px;
        font-family: PangMenZhengDao;
        font-weight: 400;
        text-align: left;
        color: #ffffff;
        background-size: 100% 100%;
      }
      .recommend_list {
        margin-top: 10px;
        height: 530px;
        overflow: hidden;
        overflow-y: auto;
        padding: 5px;
        .item {
          position: relative;
          transition: all 0.3s ease;
          height: 99px;
          box-sizing: border-box;
          padding-left: 16px;
          display: flex;
          border-bottom: 1px solid #f5f5f5;
          align-items: center;
          cursor: pointer;
          i {
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 20px;
            color: #8d8787d0;
          }
          .i_left {
            height: 52px;
            width: 52px;
            position: relative;
            border: 1px solid #f3ecec;
            border-radius: 10px;
            img {
              max-height: 52px;
              max-width: 52px;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }
          .i_right {
            margin-left: 16px;
            .i_r_top {
              font-size: 16px;
              font-family: Source Han Sans CN, Source Han Sans CN-Bold;
              font-weight: 700;
              text-align: left;
              color: #333333;
            }
            .i_r_bot {
              margin-top: 15px;
              font-size: 14px;
              font-family: Source Han Sans CN, Source Han Sans CN-Regular;
              font-weight: 400;
              text-align: left;
              color: #a8a8a8;
            }
          }
        }
        .item.active {
          border-radius: 4px;
          box-shadow: 1px 1.73px 12px 0px rgba(0, 0, 0, 0.1);
        }
      }
      .viewMore {
        cursor: pointer;
        position: absolute;
        right: 16px;
        bottom: 20px;
        font-size: 14px;
        font-family: Source Han Sans CN, Source Han Sans CN-Regular;
        font-weight: 400;
        text-align: right;
        color: #2774f7;
      }
    }
  }
}
.introduce_tooltip,
.rejectContent_tooltip {
  max-width: 900px;
  background: #fff;
  color: #000;
  display: inline-block;
  font-size: 14px;
}
</style>

